import { ReactComponent as FooterLogo } from '../../../assets/Icons/Landing/footer-logo.svg'
import { ReactComponent as LinkedinIcon } from '../../../assets/Icons/Landing/linkedin.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/Icons/Landing/twitter.svg'
import { ReactComponent as LogoIcon } from '../../../assets/Icons/Landing/logo.svg'

import React, { useState } from 'react'
import * as S from './style'

const Footer = props => {
    const [email, setEmail] = useState('')
    let mainInput = null
    const onSubscribe = e =>
        setTimeout(() => {
            mainInput.value = ''
            setEmail('')
        }, 300)

    return (
        <S.Footer enabled={email.length}>
            <div className="links">
                <ul>
                    <li className="title">Information</li>
                    {/* <li>
                        <a href="https://springrecruit.com/blog/" target="=_blank">
                            BLOG
                        </a>
                    </li>
                    <li>
                        <a href="mailto:press@springworks.in">Press Enquiries</a>
                    </li>
                    <li>
                        <a href="https://www.springworks.in/careers/">Work With Us</a>
                    </li> */}
                    <li>
                        <a href="http://springworks.in/privacy-policy/">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://springrecruit-assets.s3.amazonaws.com/TnC-revised-1-aug-20.pdf">
                            Terms & Conditions
                        </a>
                    </li>
                    {/* <li>
                        <a href="https://www.springworks.in/">Back to Springworks</a>
                    </li> */}
                </ul>
                <ul>
                    <li className="title">Product</li>
                    <li>
                        <a href="/">SpringRecruit</a>
                    </li>
                    <li>
                        <a href="/signup/">Signup</a>
                    </li>
                    <li>
                        <a href="/signin/">Login</a>
                    </li>
                    <li>
                        <a href="/webform/">Webform</a>
                    </li>
                </ul>
                {/* <ul>
                    <li className="title">Resources</li>
                    <li>
                        <a href="https://springrecruit.com/blog/">Blog</a>
                    </li>
                    <li>
                        <a href="https://springrecruit.com/blog/applicant-tracking-systems-guide/">Guide</a>
                    </li>
                </ul> */}
                <ul>
                    <li className="title">Contact Us</li>
                    <li>
                        <a href="mailto:support@springworks.in">support@springrecruit.in</a>
                    </li>
                    <li className="title">Office</li>
                    <li>
                        {/* <p>
                            1447 2nd Street, Suite
                            <br />
                            200, Santa Monica, CA
                            <br />
                            90401 United States
                        </p> */}
                        <p>
                            Gurugram
                            <br />
                            Haryana
                            <br />
                            India
                        </p>
                    </li>
                </ul>
            </div>
            <div className="bottom">
                {/* <form
                    action="https://springrole.us14.list-manage.com/subscribe/post?u=ca6975e5d8eb9feae8b42d901&amp;id=298078a843"
                    method="post"
                    name="recruit-subscribe-form"
                    target="_blank"
                    onSubmit={onSubscribe}
                >
                    <S.H2>Subscribe to Springworks</S.H2>
                    <input
                        type="email"
                        placeholder="Email Address"
                        name="EMAIL"
                        ref={ref => (mainInput = ref)}
                        onChange={({ target: { value } }) => setEmail(value.trim())}
                    />
                    <S.Button type="submit" name="subscribe" disabled={!email.length}>
                        SUBSCRIBE
                    </S.Button>
                    <p>
                        Exclusive invites to webinars, highly curated eBooks and access to content for remote work
                        resources picked up by our editor
                    </p>
                    <div className="copyright">
                        <TwitterIcon />
                        <LinkedinIcon />
                    </div>
                </form> */}
                <LogoIcon />
            </div>
        </S.Footer>
    )
}

export default Footer
